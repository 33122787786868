<template>
  <div>
    <!-- Page Heading -->
    <div class="ps-form__header">
      <h3>Create Setting</h3>
    </div>

    <!-- Content Row -->
    <SettingForm></SettingForm>

  </div>
</template>

<script>
import SettingForm from "./SettingForm";
export default {
  name: "Create",
  components: {SettingForm}
}
</script>

<style scoped>

</style>