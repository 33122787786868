<template>
  <div class="ps-form__content">
    <div class="row mt-3 mb-3" v-if="message">
      <div class="col-12">
        <small class="text-danger" v-if="isError">{{ message }}</small>
        <small class="text-success" v-else>{{ message }}</small>
      </div>
    </div>
    <div class="form-group">
      <label>Name<sup>*</sup> </label>
      <div class="form-group__content">
        <input class="form-control" type="text" v-model="form.title" placeholder="" />
        <small v-if="errors && errors.title" class="phone_error text-danger">
          {{ errors.title[0] }}
        </small>
      </div>
    </div>
    <div class="form-group">
      <label>Value<sup>*</sup> </label>
      <div class="form-group__content">
        <input class="form-control" v-model="form.value" type="text" />
        <small v-if="errors && errors.value" class="text-danger">
          {{ errors.value[0] }}
        </small>
      </div>
    </div>
    <div class="form-group form-check">
      <input
          required
          type="checkbox"
          class="form-check-input"
          id="agree_to_terms"
          v-model="form.is_active"
      />
      <label class="ml-3 form-check-label" for="agree_to_terms">Active  (*)</label
      >
      <small v-if="errors && errors.is_active" class="text-danger">
        {{ errors.is_active[0] }}
      </small>
    </div>
    <div class="form-group">
      <button class="ps-btn" @click.prevent="onSubmit()">
        {{ isLoading ? "Submitting..." : "Submit" }}
        <i v-if="!isLoading" class="icon icon-right-arrow ml-1"></i>
        <span v-if="isLoading" class="spinner-border text-light" role="status"></span>
      </button>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "SettingForm",
  props: {
    settingId: String
  },
  data() {
    return {
      setting: null,
      form: {
        name: '',
        value: '',
        is_active: '',
      },
      show: true,
      isLoading: false,
      errors: null,
      categories: [],
      message: null
    }
  },
  async created() {
    if (this.settingId) {
      axios.get('settings/' + this.settingId).then((response) => {
        this.setting = response.data;
        this.form.value = this.setting.value;
        this.form.title = this.setting.title;
        this.form.is_active = this.setting.is_active;
      });
    }
  },
  methods: {
    async onSubmit()
    {
      console.log(this.settingId)
      if (this.settingId) {
        await this.updateSetting()
      } else {
        await this.createSetting()
      }
    },

    createSetting()
    {
      this.isLoading = true;
      axios.post('settings', this.form).then((response) => {
        this.isLoading = false;
        setTimeout(() => {
          this.$router.push({'name': 'admin_settings'})
        }, 1000)
      }).catch((error) => {
        this.isLoading = false;
      });
    },

    updateSetting()
    {
      this.isLoading = true;
      axios.put('settings/' + this.settingId, this.form).then((response) => {
        this.isLoading = false;
        setTimeout(() => {
          this.$router.push({'name': 'admin_settings'})
        }, 1000)

      }).catch((error) => {
        let message = '';
        this.isLoading = false;
      });
    }
  }
}
</script>

<style scoped>

</style>